<template>
  <div>

    <form-wizard
        color="#2d71b8"
        :title="null"
        :subtitle="null"
        layout="vertical"
        finish-button-text="Update"
        back-button-text="Previous"
        class="wizard-vertical mb-3"
        @on-complete="editModule"

    >
      <tab-content title="Update Fx Rate" icon="feather icon-dollar-sign">
        <validation-observer ref="moduleDetailsRules" v-if="!finished">
          <b-form>
            <b-row>
              <b-col
                  cols="12"
                  class="mb-2"
              >
                <h5 class="mb-0">
                  Fx Rate Details
                </h5>
              </b-col>
              <b-col md="6">

                <b-form-group
                    label="Rate (BDT/Dollar)"
                    label-for="fh-title"

                >
                  <validation-provider
                      #default="{ errors }"
                      rules="required"
                      name="Rate"
                  >
                    <b-input-group
                        class="input-group-merge"
                    >
                      <b-input-group-prepend is-text>
                        <feather-icon icon="DollarSignIcon"/>
                      </b-input-group-prepend>
                      <b-form-input
                          id="fh-name"
                          placeholder="100"
                          v-model="modify.rate"
                      />
                    </b-input-group>
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
            </b-row>
          </b-form>
        </validation-observer>
      </tab-content>

    </form-wizard>
  </div>
</template>

<script>
import {FormWizard, TabContent} from 'vue-form-wizard'
import vSelect from 'vue-select'
import 'vue-form-wizard/dist/vue-form-wizard.min.css'
import 'quill/dist/quill.core.css'
import 'quill/dist/quill.snow.css'
import 'quill/dist/quill.bubble.css'
import {quillEditor} from 'vue-quill-editor'
import {
  BRow,
  BFormFile,
  BCol,
  BFormGroup,
  BFormInput,
  BFormTextarea,
  BForm,
  BButton,
  BFormRating,
  BFormInvalidFeedback,
  BInputGroup,
  BInputGroupPrepend,
  BFormValidFeedback,
  BImg, BContainer
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import {ValidationProvider, ValidationObserver} from 'vee-validate'
import {required, email} from '@validations'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import {mapState} from "vuex";
import knowledgeBaseCategory from "@/views/pages/Knowledge-base/KnowledgeBaseCategory.vue";

export default {
  components: {
    FormWizard,
    TabContent,
    BRow,
    BCol,
    BFormGroup,
    BFormInput,
    BFormFile,
    BForm,
    BFormTextarea,
    BButton,
    BFormRating,
    BFormInvalidFeedback,
    BInputGroup,
    BInputGroupPrepend,
    BFormValidFeedback,
    BImg, BContainer,
    vSelect,
    ValidationProvider,
    ValidationObserver,
    // eslint-disable-next-line vue/no-unused-components
    ToastificationContent,
    quillEditor,
  },
  directives: {
    Ripple,
  },
  watch: {},

  computed: {


    ...mapState('fxRateStore', ['FX', "FXLoading", "FXError"]),
  },
  data() {
    return {


      modify: {
        rate : 0.0
      },
      finished: false,
    }
  },
  created() {

    this.$store.dispatch('fxRateStore/fetchFX').then(result=>{
      console.log(result)
      this.modify.rate=result.rate

    })


  },
  methods: {

    editModule() {
      if (this.moduleAdded) {
        return new Promise((resolve, reject) => {
          resolve(true)
        })
      } else {
        return new Promise((resolve, reject) => {
          this.$refs.moduleDetailsRules.validate().then(async success => {

            if (success) {
              await this.$store.dispatch('fxRateStore/updateFX', this.modify).then(result => {
                console.log(result)
                if (result.code != 200) {
                  this.$toast({
                    component: ToastificationContent,
                    position: 'bottom-end',
                    props: {
                      title: ' Error',
                      icon: 'AlertOctagonIcon',
                      variant: 'danger',
                      text: result.response,
                    },
                  })
                  reject()
                } else {

                  this.$toast({
                    component: ToastificationContent,
                    position: 'top-right',
                    props: {
                      title: result.code == 400 ? 'Error' : `Success`,
                      icon: result.code == 400 ? 'ThumbsDownIcon' : 'ThumbsUpIcon',
                      variant: result.code == 400 ? 'danger' : 'success',
                      text: result.response,
                    },
                  })



                  resolve(true)
                }
              })
            } else {
              reject()
            }
          })
        })
      }
    },






  }
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-wizard.scss';
@import '@core/scss/vue/libs/vue-select.scss';
@import '@core/scss/vue/pages/page-profile.scss';
</style>
